/* You can add global styles to this file, and also import other style files */
@import "~swiper/scss";
@import "~swiper/scss/navigation";
@import "~swiper/scss/pagination";
// @import "~swiper/swiper-bundle";
$naranja: #f44b2d;
$negro: #2a2a2a;
$rojoYoutube: #ff0000;
html,
body {
  height: 100%;
  overflow-x: hidden;
}
html {
  top: 0 !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.pac-container {
  top: 63% !important;
}
body {
  margin: 0;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
}
:root {
  --swiper-theme-color: white;
}
.swiper {
  width: 100%;
  // height: 100%;
}
.bg-gris-claro {
  background-color: #f7f8f9 !important;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 20px;
}
.card-effect {
  border: 1px solid lightgray;
  border-radius: 0.25rem;
}
.card-effect:hover {
  border-radius: 0.25rem;
  box-shadow: 0 0 9px -1px;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 99%;
}
.border-radius-0 {
  border-radius: 0 !important;
}
.cursor-pointer {
  cursor: pointer;
}
.bg-columna-gris {
  background-color: #f1f1f1;
}
.line-height-1 {
  line-height: 1;
}
.modal-backdrop {
  z-index: 1 !important;
}
.btn-orange {
  background-color: $naranja;
}
.btn-negro {
  background-color: $negro;
  color: #fff;
  border-radius: 10px;
}
.btn-verde {
  background-color: #69983a;
  color: #ffffff;
  border-radius: 10px;
}
.border-naranja {
  border-color: 1px solid $naranja;
}
.text-color-youtube {
  color: $rojoYoutube;
}
.text-naranja {
  color: $naranja;
}
a.text-naranja:hover {
  color: $naranja;
}
.btn-naranja-light {
  background-color: $naranja;
  color: #fff;
}
.btn-naranja-light:hover {
  background-color: $naranja;
  border: 1px solid $naranja;
  color: #fff;
}
.btn-naranja {
  background-color: #b83922;
  border: 1px solid #b83922;
  color: #ffffff;
  border-radius: 10px;
}
.btn-naranja:hover {
  background-color: #b83922;
  border: 1px solid #b83922;
  color: #fff;
  border-radius: 10px;
}
.btn-outline-naranja-circle {
  border: 1px solid $naranja;
  background-color: #fff;
  color: #000;
}
.btn-outline-naranja-circle:hover {
  border: 1px solid $naranja;
  background-color: #fff;
  color: #000;
}
.bg-naranja {
  background-color: $naranja;
}
.bg-negro {
  background-color: #242424;
}
.bg-gris:active {
  background-color: #ccc;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple {
  background-color: #212529 !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #212529;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #212529;
  border-color: #212529;
}
.correo-usuario {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 240px;
}
.webcam-wrapper video,
canvas {
  width: 350px;
  height: auto;
}
.circle {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid #000;
}
.invalid {
  border: 1px solid red;
}
.gap-13 {
  gap: 13px;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background: $naranja !important;
}
.btn-link {
  box-shadow: 0 0 0 0;
  border: 0;
}
.btn-link.active {
  color: $naranja !important;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: $naranja;
}
.form-check-input:checked {
  background-color: $negro;
  border-color: $negro;
}
.estilo-tarjeta {
  border-radius: 8px;
  box-shadow: -1px -1px 4px 0px #99979c;
}
.border-bottom {
  border-bottom: 2px solid #ccc !important;
}
.border-send {
  border: 2px solid #000 !important;
}
textarea {
  resize: none;
}
.bg-gris-chat-taller {
  background-color: #b1afb0;
}
.bg-gris-chat-usuario {
  background-color: #e0e0e0;
}
#contenedorChat {
  max-height: 60vh;
  overflow-y: scroll;
}
.microfono-chat {
  font-size: 50px;
}
.bg-grisclaro {
  background-color: #e2e0e1;
}

@media (max-width: 576px) {
  .info-vehiculo {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 41vw;
  }
}

@media (max-width: 991px) {
  .card-effect {
    border: 1px solid lightgray;
    border-radius: 0.25rem;
  }
  .bg-grisclaro {
    background-color: #fff;
  }
}

@media (max-width: 992px) {
  .box-shadow-none {
    box-shadow: none;
  }
}
@media (max-width: 992px) {
  input[type="date"]:not(.has-value):before {
    color: #5a5a5a;
    content: attr(placeholder);
  }
  input[type="time"]:not(.has-value):before {
    color: #5a5a5a;
    content: attr(placeholder);
  }
}
@media (min-width: 992px) {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 10px;
  }

  ::-webkit-scrollbar-button:increment,
  ::-webkit-scrollbar-button {
    display: none;
  }

  ::-webkit-scrollbar:horizontal {
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $negro;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}
@media (min-width: 2230px) {
  .swiper-wrapper {
    justify-content: center;
    align-items: center;
  }
}
